import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import SearchInput from "./searchInput"

export default connectSearchBox(
    ({ refine, currentRefinement, className, onFocus, ...props }) => (
        <SearchInput
            refine={refine}
            currentRefinement={currentRefinement}
            className={className}
            onFocus={onFocus}
            {...props}
        />
    )
)
