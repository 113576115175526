import React from "react"
import CookieConsent from "react-cookie-consent";
import TransitionLink from "gatsby-plugin-transition-link";

const CookieBar = (exitTransition, entryTransition) => {

    return (
        <CookieConsent
            location="bottom"
            buttonText="Understood"
            cookieName="myAwesomeCookieName2"
            // hideOnAccept="false"
            disableStyles="false"
            style={{
                background: "rgb(16 16 16 / 98%)",
                padding: "1rem 2rem",
                gridTemplateColumns: "1fr 1fr",
                justifyContent: "center",
                bottom: "0px",
                alignItems: "center",
                color: "white",
                display: "flex",
                flexWrap: "wrap",
                left: "0px2",
                position: "fixed",
                width: "100%",
                zIndex: "999",
            }}
            buttonStyle={{
                background: "transparent",
                border: "1px solid #fff",
                color: "#ffffff",
                fontSize: "13px",
                minWidth: "160px",
                marginLeft: "2rem",
                padding: "1rem",
                borderRadius: "4px",
            }}
            buttonClasses={"cookie-accept"}
            expires={60}
        >
            By using this website, you automatically accept that we use cookies.{" "}
            <span style={{ textAlign: "center"}}>
                <TransitionLink
                    to={`/Cookie-Policy`}
                    exit={exitTransition}
                    entry={entryTransition}
                    style={{
                        fontSize: "10px",
                        textTransform: "unset",
                        display: "inline-block",
                        paddingLeft: ".5rem",
                        textDecoration: "underline",
                        color: "#ffffff",
                    }}
                >
                        What for?
                    </TransitionLink>
            </span>
        </CookieConsent>
    );

}

export default CookieBar
