import React, {useEffect, useRef} from "react"
import Image8 from "../images/logo_white.png";
import {TweenLite} from "gsap";


const Contact = () => {

    const fullName = 'fullName';

    let contact = useRef(null);
    let contactBrand = useRef(null);
    let contactForm = useRef(null);

    useEffect(() => {

        TweenLite.to(
            contact,
            .2,
            {
                opacity: 1,
            }
        );

        const contactST = {
            trigger: contact,
            start: "top bottom", // when the top of the trigger hits the top of the viewport
            end: "center bottom",
            toggleActions: "restart none revers pause",
            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        };

        TweenLite.from(
            contactBrand,
            1,
            {
                scrollTrigger: contactST,
                opacity: 0,
                scale: .2,
            }
        );

        TweenLite.from(
            contactForm,
            1,
            {
                scrollTrigger: contactST,
                y: 100,
                opacity: 0,
            }
        );

    },[contact])

    return (
        <section ref={el => (contact = el)} id="contact" className="regular-section footer-top anchor">
            <div ref={el => (contactBrand = el)} className="footer-mail">
                <img src={Image8} alt="logo"/>
                <p>info@rockaholik.com</p>
            </div>
            <div ref={el => (contactForm= el)} className="form-wrapper">
                <form action="/confirm-page" name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
                    <h2>Contact</h2>
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <label htmlFor={fullName}>Your name:</label>
                    <input type="text" id={fullName} name="name" required/>
                    <label htmlFor="email">Your e-mail:</label>
                    <input type="email" id="email" name="email" required/>
                    <label htmlFor="message">Your message:</label>
                    <textarea name="message" id="message" cols="30" rows="4" required/>
                    <input className="btn-cta" type="submit" value="Submit"/>
                </form>
            </div>
        </section>
   );
}

export default Contact
