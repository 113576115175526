import React from 'react';
import Cancel from "../../images/cancel.svg";

const SearchInput = ({ refine, currentRefinement, onFocus, hasFocus, searchInput, handleFocus }) => {

    return (
        <form >
            <button
                className={hasFocus ? "search true" : "search"}
                onClick={() => handleFocus()}
                onKeyDown={() => handleFocus()}
                type="button"
                aria-label="Save"
            >
                <i className="icon icon-search search-icon"/>
            </button>
            <div className={hasFocus ? "SearchInput true" : "SearchInput"}>
                <i className="icon icon-search search-icon"/>
                <input

                    ref={searchInput}
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={e => refine(e.target.value)}
                    value={currentRefinement}
                    onFocus={onFocus}
                />
                <div>
                    <img
                        src={Cancel}
                        alt="cancel"
                        onClick={() => handleFocus()}
                        onKeyDown={() => handleFocus()}
                        aria-label="Save"
                    />
                </div>
            </div>
        </form>
    )
}

export default SearchInput

