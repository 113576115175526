import React, {useRef, useState} from 'react';
import { graphql, StaticQuery } from "gatsby";
import useClickOutside from "../useClickOutside";
import Tooltip from "./tooltip"

import Pen from '../../images/pen-tool.svg'
import Cancel from '../../images/cancel.svg'
import Clock from '../../images/wall-clock.svg'
import TransitionLink from "gatsby-plugin-transition-link";


const BlogSidebar = ({setOpen, exitTransition, entryTransition }) => {

    const sidebar =  useRef(null)
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const sidebarToggle = () => {
        setOpen(false)
        setSidebarOpen(!sidebarOpen)
        if (sidebarOpen === false) {
            document.body.classList.add("overflowHidden");
        }
        if (sidebarOpen === true) {
            document.body.classList.remove("overflowHidden");
        }
    }

    function sidebarClose(){
        setSidebarOpen(false);
        document.body.classList.remove("overflowHidden");
    }

    let sideBarWrapper = ['side-menu-wrapper'];
    let sideBar = ['mobile-side-menu'];
    if (sidebarOpen) {
        sideBarWrapper += (' side-menu-wrapper-open');
        sideBar += (' mobile-side-menu-open');
    }

    if (sidebarOpen === true) {
        useClickOutside(sidebar, () => setSidebarOpen(false))
    }

    return (

        <StaticQuery
            query={graphql`
                query {
                    allStrapiBlogCategories(sort: {order: ASC, fields: Order}) {
                        edges {
                          node {
                            name
                            slug
                            page_title
                            icon {
                              publicURL
                            }
                          }
                        }
                    }
                }
                `}
            render={data => (
                <div ref={sidebar} className={sideBarWrapper}>
                    <button
                        className="mobile-side-menu-btn"
                        onClick={() => sidebarToggle()}
                        onKeyDown={() => sidebarToggle()}
                    >
                        <span>Blog</span>
                    </button>
                    <ul className={sideBar}>
                        <li>
                            <TransitionLink
                                class="side-menu-vertical"
                                activeClassName="active"
                                to={"/blog"}
                                onClick={() => sidebarClose()}
                                onKeyDown={() => sidebarClose()}
                                exit={exitTransition}
                                entry={entryTransition}
                            >
                                <img src={Pen} alt="blog" />
                                <span>Blog</span>
                            </TransitionLink>
                        </li>

                        {data.allStrapiBlogCategories.edges.map((category, i) => {
                            return (
                                <li>
                                    <TransitionLink
                                        activeClassName="active"
                                        to={"/blog/" + category.node.slug}
                                        onClick={() => sidebarClose()}
                                        onKeyDown={() => sidebarClose()}
                                        exit={exitTransition}
                                        entry={entryTransition}
                                    >
                                        <img src={category.node.icon.publicURL} alt={category.node.slug}/>
                                        <span>{category.node.name}</span>
                                    </TransitionLink>
                                </li>
                            )
                        })}

                        <li>
                            <TransitionLink
                                class="side-menu-vertical"
                                activeClassName="active"
                                to={"/latest-articles"}
                                onClick={() => sidebarClose()}
                                onKeyDown={() => sidebarClose()}
                                exit={exitTransition}
                                entry={entryTransition}
                            >
                                <img src={Clock} alt="latest" />
                                <span>Latest</span>
                            </TransitionLink>
                        </li>
                        <li className="mobile-sm-close">
                            <button
                                onClick={() => sidebarToggle()}
                                onKeyDown={() => sidebarToggle()}
                            >
                                <img src={Cancel} alt="cancel" />
                            </button>
                        </li>
                    </ul>

                    <ul className="side-menu">
                        <li>
                            <Tooltip text="Highlighted Articles">
                                <TransitionLink
                                    class="side-menu-vertical"
                                    activeClassName="active"
                                    to={"/blog"}
                                    onClick={() => sidebarClose()}
                                    onKeyDown={() => sidebarClose()}
                                    exit={exitTransition}
                                    entry={entryTransition}
                                >
                                    Blog
                                </TransitionLink>
                            </Tooltip>
                        </li>
                        <li>
                            <span className="side-menu-line"> </span>
                        </li>

                        {data.allStrapiBlogCategories.edges.map((category, i) => {
                            return (
                                <li>
                                    <Tooltip text={category.node.name}>
                                        <TransitionLink
                                            activeClassName="active"
                                            to={"/blog/" + category.node.slug}
                                            onClick={() => sidebarClose()}
                                            onKeyDown={() => sidebarClose()}
                                            exit={exitTransition}
                                            entry={entryTransition}
                                        >
                                            <img src={category.node.icon.publicURL} alt={category.node.slug}/>
                                        </TransitionLink>
                                    </Tooltip>
                                </li>
                            )
                        })}

                        <li>
                            <span className="side-menu-line"> </span>
                        </li>
                        <li>
                            <Tooltip text="Latest Articles">
                                <TransitionLink
                                    class="side-menu-vertical"
                                    activeClassName="active"
                                    to={"/latest-articles"}
                                    onClick={() => sidebarClose()}
                                    onKeyDown={() => sidebarClose()}
                                    exit={exitTransition}
                                    entry={entryTransition}
                                >
                                    Latest
                                </TransitionLink>
                            </Tooltip>
                        </li>
                    </ul>
                </div>
            )}
        />
    )
}

export default BlogSidebar
