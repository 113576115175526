import React, {  useRef, useState} from "react"
import Logo from '../images/logo_white.png'
import Nav from "./navigation/nav";
import Search from "./search/search";
import scrollTo from "gatsby-plugin-smoothscroll";
import useClickOutside from "./useClickOutside";
import TransitionLink from "gatsby-plugin-transition-link";

const articleSearchIndices = [{ name: `articles`, title: `articles` }]
const productSearchIndices = [{ name: `products`, title: `products` }]

const Header  = ({ exitTransition, exitSimpleTransition, entryTransition }) => {

    const navBar = useRef(null)

    const [open, setOpen] = useState(false)

    if (open === true) {
        useClickOutside(navBar, () => setOpen(false))
    }

    function handleOpen(){
        setOpen(!open);
        if (open === true) {
            document.body.classList.remove("overflowHidden");
        }
        else {
            document.body.classList.add("overflowHidden");
        }
    }

    let hamburger = ['btn-hamburger'];

    if (open === true) {
        hamburger += (' open');
    }
    else {
    }

    return (
        <section className="header" ref={navBar}>
            <TransitionLink
                className="brand-logo-wrapper"
                to="/#home"
                onClick={() => scrollTo('#home')}
                exit={exitSimpleTransition}
                entry={entryTransition}
            >
                <img className="brand-logo" src={Logo} alt="rockaholik-logo"/>
            </TransitionLink>
            <Nav
                open={open}
                setOpen={setOpen}
                exitTransition={exitTransition}
                entryTransition={entryTransition}
            />
            <Search
                articleIndices={articleSearchIndices}
                productIndices={productSearchIndices}
                exitTransition={exitTransition}
                entryTransition={entryTransition}
                setOpen={setOpen}
            />
            <div className="burger-container">
                <div
                    className={hamburger}
                    onClick={() => handleOpen()}
                    onKeyDown={() => handleOpen()}
                    role="button"
                    aria-label="Save"
                    tabIndex="0"
                >
                    <div />
                    <div />
                    <div />
                </div>
            </div>

        </section>
    )
}

export default Header
