import algoliasearch from "algoliasearch/lite"
import {createRef, default as React, useRef, useState} from "react"
import { InstantSearch } from "react-instantsearch-dom"
import { ThemeProvider } from "styled-components"
import SearchBox from "./searchBox"
import ProductSearchResult from "./productSearchResult"
import ArticleSearchResult from "./articleSearchResult"
import useClickOutside from "../useClickOutside";

const theme = {
    foreground: "#050505",
    background: "white",
    faded: "#888",
}

export default function Search({ articleIndices, productIndices, setOpen, exitTransition, entryTransition }) {

    const rootRef = createRef()
    const [query, setQuery] = useState()
    const [hasFocus, setFocus] = useState(false)
    const searchInput = useRef(null)

    const handleFocus = () => {
        setOpen(false)
        setFocus(!hasFocus);
        if (hasFocus === false) {
            searchInput.current.focus();
            document.body.classList.add("overflowHidden");
        }
        if (hasFocus === true) {
            rootRef.current.focus();
            document.body.classList.remove("overflowHidden");
        }
    }

    if (hasFocus === true) {
        useClickOutside(rootRef, () => setFocus(false))
    }

    const searchClient = algoliasearch(
        "QJPWPDDOCE",
        "a9e784309b6a8ac8c56639648402d7bf"
    )


    function showProducts() {
        let productHits = document.querySelector('.productResults');
        let articleHits = document.querySelector('.articleResults');
        let productHitsLabel = document.querySelector('.productHitsLabel');
        let articleHitsLabel = document.querySelector('.articleHitsLabel');

        articleHits.classList.remove("show");
        productHits.classList.add("show");
        articleHitsLabel.classList.remove("active");
        productHitsLabel.classList.add("active");

    }

    function showArticles() {
        let productHits = document.querySelector('.productResults');
        let articleHits = document.querySelector('.articleResults');
        let productHitsLabel = document.querySelector('.productHitsLabel');
        let articleHitsLabel = document.querySelector('.articleHitsLabel')

        productHits.classList.remove("show");
        articleHits.classList.add("show");
        productHitsLabel.classList.remove("active");
        articleHitsLabel.classList.add("active");
    }

    return (
        <ThemeProvider theme={theme}>
            <div className='search-wrapper' ref={rootRef}>
                <InstantSearch
                    searchClient={searchClient}
                    indexName={'products'}
                    onSearchStateChange={({ query }) => setQuery(query)}
                >
                    <SearchBox
                        hasFocus={hasFocus}
                        onFocus={() => setFocus(true)}
                        searchInput={searchInput}
                        handleFocus={handleFocus}
                    />
                    <div className={hasFocus && query && query.length > 0 ? "search-result true" : "search-result"}>
                        <div className={"hit-nav"}>
                            <div
                                className={"hit-nav-item articleHitsLabel"}
                                onClick={() => showArticles()}
                                onKeyDown={() => showArticles()}
                                role="button"
                                aria-label="Save"
                                tabIndex="0"
                            >
                            </div>
                            <div
                                className={"hit-nav-item productHitsLabel active"}
                                onClick={() => showProducts()}
                                onKeyDown={() => showProducts()}
                                role="button"
                                aria-label="Save"
                                tabIndex="0"
                            >
                            </div>
                        </div>
                        <ArticleSearchResult
                            className={"articleResults"}
                            indices={articleIndices}
                            exitTransition={exitTransition}
                            entryTransition={entryTransition}
                        />
                        <ProductSearchResult
                            className={"productResults show"}
                            indices={productIndices}
                            exitTransition={exitTransition}
                            entryTransition={entryTransition}
                        />
                    </div>
                </InstantSearch>
            </div>
        </ThemeProvider>
    )
}
