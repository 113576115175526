import React, {useState} from 'react';
import {graphql, Link, StaticQuery} from "gatsby";
import TransitionLink from "gatsby-plugin-transition-link";

const Menu = ({ open, setOpen, exitTransition , entryTransition}) => {

    const [dropdownOpen, setDropdownOpenOpen] = useState(false)

    const dropdownToggle = () => setDropdownOpenOpen(!dropdownOpen);

    function handleClose(){
        setOpen(false);
        document.body.classList.remove("overflowHidden");
    }

    let dropdown = ['dropdown-menu-items'];
    if (dropdownOpen) {
        dropdown += (' dropdown-menu-items-open');
    }

    let navContainer = ['nav-container'];
    let nav = ['nav'];
    if (open) {
        navContainer += (' open');
        nav += (' open');
    }

    return (
        <div className={navContainer}>
            <ul className={nav}>
                <StaticQuery
                    query={graphql`
                        query {
                            strapiNavigation {
                              top_menu {
                                Link
                                URL
                                Label
                                dropdown_menu_item {
                                  Link
                                  URL
                                }
                              }
                            }
                        }
                        `}
                    render={data =>
                     data.strapiNavigation.top_menu.map((menu) => {
                            if (menu.dropdown_menu_item !== null) {
                                return (
                                    <li className="nav-item dropdown-menu">
                                        <a
                                            tabIndex={0}
                                            onClick={() => dropdownToggle()}
                                            onKeyDown={() => dropdownToggle()}
                                        >{menu.Label}</a>
                                        <ul className={dropdown}>
                                            { menu.dropdown_menu_item.map((submenu) =>
                                            <li className="nav-item" key={menu.URL}>
                                                <TransitionLink
                                                    className="nav-link"
                                                    activeClassName="active"
                                                    to={submenu.URL}
                                                    exit={exitTransition}
                                                    entry={entryTransition}

                                                    onClick={() => handleClose()}
                                                    onKeyDown={() => handleClose()}
                                                >
                                                    {submenu.Link}
                                                </TransitionLink>
                                            </li>
                                        )}
                                        </ul>
                                    </li>
                                )
                            }
                            else {
                                return (
                                    <li className="nav-item" key={menu.URL}>
                                        <TransitionLink
                                            to={menu.URL}
                                            className="nav-link"
                                            activeClassName="active"
                                            onClick={() => handleClose()}
                                            onKeyDown={() => handleClose()}
                                            exit={exitTransition}
                                            entry={entryTransition}
                                        >
                                            {menu.Link}
                                        </TransitionLink>
                                    </li>
                                )
                            }
                        })
                    }
                />
                <li className="nav-item">
                    <Link
                        to={'#contact'}
                        className="nav-link"
                        activeClassName="active"
                        onClick={() => handleClose()}
                        onKeyDown={() => handleClose()}
                    >
                        Contact
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default Menu
