import React, {useRef, useEffect} from 'react';
import PropTypes from "prop-types"

import "../assets/scss/main.css"
import Header from "./header"
import Seo from "./seo"
import Footer from "./footer"
import {TweenLite, Power2} from "gsap";
import scrollTo from "gatsby-plugin-smoothscroll";
import CookieBar from "./cookieConsent";

const Layout = ( {children, title, description, mainImage, blogPage } ) => {

    let bodyLayout = useRef(null);
    let mainLayout = useRef(null);
    let footer = useRef(null);

    useEffect(() => {

        TweenLite.to(
            mainLayout,
            .3,
            {
                opacity: 1,
                ease: Power2.easeOut,
            }
        );

        TweenLite.to(
            footer,
            .6,
            {
                opacity: 1,
                ease: Power2.easeOut,
            }
        );

        let body = document.querySelector("body");

        setTimeout(() => {
            body.classList.add("loaded")
        }, 350);


    },[mainLayout, bodyLayout, footer])

    const TRANSITION_LENGTH = .5

    const exitTransition = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPage(),
    }

    const exitSimpleTransition = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPageSimple(),
    }

    const exitReverseTransition = {
        length: TRANSITION_LENGTH,
        trigger: () =>  animateExitPageReverse(),
    }

    const entryTransition = {
        delay: TRANSITION_LENGTH,
        trigger: () => animateEntryPage(),
    }

    function animateExitPage() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                x: '60%',
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateExitPageSimple() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                // x: '60%',
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateExitPageReverse() {

        let html = document.querySelector("html");
        let mainLayout = document.querySelector('.main');
        let footer = document.querySelector('.footer');

        html.classList.add("smoothScrollOff")
        mainLayout.classList.add("invisible")

        TweenLite.to(
            mainLayout,
            .8,
            {
                opacity: 0,
                x: '-60%',
                ease: Power2.easeInOut,
            }
        );

        TweenLite.to(
            footer,
            .4,
            {
                opacity: 0,
                ease: Power2.easeInOut,
            }
        );

        setTimeout(() => {
            scrollTo('#home')
        }, 450);
    }

    function animateEntryPage() {

        let html = document.querySelector("html");

        setTimeout(() => {
            html.classList.remove("smoothScrollOff")
        }, 120);
    }

    return (
        <div ref={el => (bodyLayout = el)} id="home" className={"layout" + " " + blogPage}>
            <Seo title={title} description={description} mainImage={mainImage}/>
            <Header
                className="header"
                exitTransition={exitTransition}
                exitSimpleTransition={exitSimpleTransition}
                entryTransition={entryTransition}
            />
            <main ref={el => (mainLayout = el)} className={"main"}>{children}</main>
            <section ref={el => (footer = el)} className="footer">
                <Footer
                    className="footer"
                    exitTransition={exitTransition}
                    entryTransition={entryTransition}
                />
            </section>
            <CookieBar
                exitTransition={exitTransition}
                entryTransition={entryTransition}
            />
            <div className={"layout-overlay"}> </div>
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
