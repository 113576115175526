import React from 'react';
import Menu from "./menu";
import BlogSidebar from "./blogSidebar"

const Nav = ({ open, setOpen, exitTransition, entryTransition }) => {


    let navbarContainer = ['navbar-container'];

    if (open) {
        navbarContainer += (' open');
    }

    return (

        <nav className={navbarContainer}>
            <Menu
                open={open}
                setOpen={setOpen}
                exitTransition={exitTransition}
                entryTransition={entryTransition}
            />
            <BlogSidebar
                setOpen={setOpen}
                exitTransition={exitTransition}
                entryTransition={entryTransition}
            />
        </nav>
    )
}

export default Nav
