import React, {useEffect, useRef} from "react"
import ScrollToTop from "../components/scrollToTop/scrollToTop"
import Facebook from '../images/Facebook.svg'
import Instagram from '../images/Instagram.svg'
import Twitter from '../images/Twitter.svg'
import Contact from "./contact";
import {TweenLite} from "gsap";
import {graphql, StaticQuery} from "gatsby";
import TransitionLink from "gatsby-plugin-transition-link";


const Footer = ({ exitTransition, entryTransition }) => {

    let footer = useRef(null);

    useEffect(() => {

        TweenLite.to(
            footer,
            .2,
            {
                opacity: 1,
            }
        );

    },[footer])

    return (
        <StaticQuery
            query={graphql`
                query {
                  content: strapiFooter {
                      content
                      facebook
                      instagram
                      pinterest
                      seoLink {
                        link
                        title
                      }
                      title
                  }
                }
            `}
            render={data => (
                <>
                    <Contact/>
                    <section ref={el => (footer = el)} className="footer-bottom-wrapper">
                        <ScrollToTop/>
                        <div className="footer-bottom">
                            <div className="footer-bottom-brand">
                                <h2>{data.content.title}</h2>
                                <p>{data.content.content}</p>
                            </div>

                            <div className="footer-bottom-second">
                                <div className="footer-links-wrapper">
                                    <ul>
                                        {data.content.seoLink.map((link, i) => {
                                            return (
                                                <li>
                                                    <TransitionLink
                                                        to={link.link}
                                                        exit={exitTransition}
                                                        entry={entryTransition}
                                                    >
                                                        {link.title}
                                                    </TransitionLink>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className="footer-social-wrapper">
                                    <a href={data.content.facebook}>
                                        <img src={Facebook} alt="Facebook"/>
                                    </a>
                                    <a href={data.content.instagram}>
                                        <img src={Instagram} alt="Instagram"/>
                                    </a>
                                    <a href={data.content.pinterest}>
                                        <img src={Twitter} alt="Twitter"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="footer-copyright">
                            <p>© 2021 Rockaholik.com</p>
                        </div>
                    </section>
                </>

            )}
        />
    );
}

export default Footer
