import { default as React } from "react"
import {
    connectStateResults,
    Highlight,
    Hits,
    Index,
} from "react-instantsearch-dom"
import TransitionLink from "gatsby-plugin-transition-link";

const AticleHitCount = connectStateResults(({ searchResults }) => {
    const hitCount = searchResults && searchResults.nbHits

    return hitCount > 0 ? (
        <div className="HitCount">
            {hitCount} result
        </div>
    ) : (<div className="HitCount">
        No items matched your search
    </div>)
})

const AticlePageHit = ({ hit, exitTransition, entryTransition }) => (


    <div>
        <TransitionLink
            to={"/blog/" + hit.blog_category.slug + "/" + hit.slug}
            exit={exitTransition}
            entry={entryTransition}
            onClick={() => {
                document.body.classList.remove("overflowHidden");
            }}
        >
            <div className="hit-text">
                <h4>
                    <Highlight attribute="title" hit={hit} tagName="mark" />
                </h4>
                <div className="articles-img-details">
                          <span className="article-img-category">
                              <img src={hit.blog_category.icon.publicURL} alt="{hit.blog_category.name}"/>
                              <span>{hit.blog_category.name}</span>
                          </span>
                </div>
            </div>
            <div className="img-wrapper">
                <img src={hit.image} alt={hit.title} />
            </div>
        </TransitionLink>
    </div>
)

const AticleHitsInIndex = ({ index }) => (
        <Index indexName={index.name}>
            <div className={"indexTitle-wrapper"}>
                <AticleHitCount />
            </div>
            <Hits
                className="Hits"
                hitComponent={function (props) {
                    return(
                        <AticlePageHit
                            {...props}
                        />
                    )
                }}
            />
        </Index>

)

const AticleSearchResult = ({ indices, className, exitTransition, entryTransition }) => (
    <div className={className}>
        {indices.map(index => (
            <AticleHitsInIndex
                index={index}
                key={index.name}
                exitTransition={exitTransition}
                entryTransition={entryTransition}
            />
        ))}
    </div>
)

export default AticleSearchResult
