import React, {useEffect} from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

const ScrollToTop = () => {

    const [scrolled, setScrolled] = React.useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 91) {
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })

    let x = ['scroll-to-top'];
    if (scrolled) {
        x.push('visible');
    }

    return (
        <div className={x.join(" ")}>
            <button className="btn-up" onClick={() => scrollTo('#home')}><i className="icons icon-up"/></button>
        </div>
    )
}

export default ScrollToTop
