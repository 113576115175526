import { default as React } from "react"
import {
    connectStateResults,
    Hits,
    Index,
} from "react-instantsearch-dom"
import TransitionLink from "gatsby-plugin-transition-link";

const ProductHitCount = connectStateResults(({ searchResults }) => {
    const hitCount = searchResults && searchResults.nbHits

    return hitCount > 0 ? (
        <div className="HitCount">
            {hitCount} result
        </div>
    ) : (<div className="HitCount">
        No items matched your search
    </div>)
})

const ProductPageHit = ( { hit, exitTransition, entryTransition } ) => (


    <div>
        <TransitionLink
            to={"/minerals/" + hit.mineral_collection_type.slug + "/" + hit.slug}
            exit={exitTransition}
            entry={entryTransition}
            onClick={() => {
                document.body.classList.remove("overflowHidden");
            }}
        >
            <div className="hit-text">
                <h4>{hit.name}</h4>
                <div className="articles-img-details">
                    <p>{hit.collection_ID}</p>
                    <p>{hit.size}</p>
                    <p>{hit.locality}</p>
                </div>
            </div>
            <div className="img-wrapper">
                <img src={hit.image} alt={hit.name} />
            </div>
        </TransitionLink>
    </div>
)

const ProductHitsInIndex = ({ index }) => (
        <Index indexName={index.name}>

            <div className="indexTitle-wrapper">
                <ProductHitCount />
            </div>

            <Hits
                className="Hits"
                hitComponent={function (props) {
                    return(
                        <ProductPageHit
                            {...props}
                        />
                    )
                }}
            />
        </Index>

)

const ProductSearchResult = ({ indices, className, exitTransition, entryTransition }) => (
    <div className={className}>
        {indices.map(index => (
            <ProductHitsInIndex
            index={index}
            key={index.name}
            exitTransition={exitTransition}
            entryTransition={entryTransition}
            />
        ))}
    </div>
)

export default ProductSearchResult
